import Rails from "@rails/ujs";
import { nav } from "bootstrap";

import { docReady, enableScrollers } from "~/utils";

docReady(() => {
  Rails.start();
  console.log(
    "%c Ci piace la gente curiosa... Continua a farti notare: https://bit.ly/gility-tech-job ",
    "color: white; background: #1B5973; border-radius: 5px; padding: 10px",
  );

  enableScrollers();
});
